import type { IAddress, ITimeslot, IGigProfile, IGig } from "hk-data/types"
import { DateTime } from "luxon"

/**
 *
 * @param address
 * @returns Address formatted as "291 Broadway, New York, NY 10007"
 */
export const formatAddress = (address: IAddress): string => {
  const {
    street_number,
    route,
    subpremise,
    locality,
    administrative_area_level_1,
    postal_code
  } = address
  let formattedAddress = `${street_number} ${route}`
  if (subpremise) {
    formattedAddress += `, ${subpremise}`
  }
  formattedAddress += `, ${locality}, ${administrative_area_level_1} ${postal_code}`
  return formattedAddress
}

/**
 *
 * @param address
 * @returns Address formatted as "291 Broadway"
 */
export const formatAddressShort = (address: IAddress): string => {
  const { street_number, route, subpremise } = address
  let formattedAddress = `${street_number} ${route}`
  if (subpremise) {
    formattedAddress += `, ${subpremise}`
  }
  return formattedAddress
}

/**
 *
 * @param address
 * @returns New York, NY
 */
export const formatAdressLocation = (address: IAddress): string => {
  return address.locality + ", " + address.administrative_area_level_1
}

export function formatDateYYYYMMDD(
  date: string,
  separator: string = ""
): string {
  return [date.slice(0, 4), date.slice(4, 6), date.slice(6, 8)].join(separator)
}

export const formatDateMMDD = (date: Date | string): string => {
  return toJSDate(date).toLocaleDateString([], {
    month: "short",
    day: "numeric"
  })
}

export const formatDateMMDDHHMM = (date: Date | string): string => {
  return toJSDate(date).toLocaleDateString([], {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  })
}

export const formatDateHHMM = (date: Date | string): string => {
  return toJSDate(date).toLocaleTimeString([], {
    hour: "numeric",
    minute: "numeric"
  })
}

export function formatPrice(priceInCents: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  })

  return formatter.format(priceInCents / 100)
}

export function formatTimeSlot(timeSlot: ITimeslot) {
  return formatDateMMDDHHMM(timeSlot.start) + "-" + formatDateHHMM(timeSlot.end)
}

export function formatTimeSlotWithTZ(timeSlot: ITimeslot, TZ: any) {
  const startDate = toJSDate(timeSlot.start)
  const endDate = toJSDate(timeSlot.end)

  return (
    DateTime.fromJSDate(startDate)
      .setZone(TZ || "America/New_York")
      .toLocaleString(DateTime.DATETIME_MED) +
    " - " +
    DateTime.fromJSDate(endDate)
      .setZone(TZ || "America/New_York")
      .toLocaleString(DateTime.TIME_SIMPLE)
  )
}

export function getTask(profile: IGigProfile, gig: IGig) {
  const matches = profile.tasks.filter((task) => task.gigKey === gig.key)
  return matches[0]
}

export const isAndroid = () => {
  // Use the navigator.userAgent to check if the user agent contains 'Android'
  return /Android/i.test(navigator.userAgent)
}

function toJSDate(date: Date | string) {
  if (typeof date === "string") {
    return new Date(date)
  }
  return date
}
